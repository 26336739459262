import VueApp from '../../../core/adapters/VueApp';
import {data, method} from '../../../core/adapters/VueComponent';

export default class ProductTabGroup extends VueApp {

    @data()
    selection: string = 'details-section';

    @method()
    setSelection(item) {
        document.getElementById(this.selection)?.classList.add('hidden');
        document.getElementById(item)?.classList.remove('hidden');
        this.selection = item;
    }
}