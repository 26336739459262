import VueApp from '../../ts/core/adapters/VueApp';
import {IntegrationExtra} from '../../ts/core/utils/IntegrationExtra';
import ProductProductionTime from '../../ts/apps/delivery/components/ProductProductionTime/ProductProductionTime';
import ProductTabGroup from '../../ts/apps/products_shared/apps/ProductTabGroup';


export default class SharedProductComponentsIntegration extends IntegrationExtra {
    setupApps(): void {
        VueApp.mount('#production-time-app', (app) => {
            app.registerComponent(ProductProductionTime);
        });

        ProductTabGroup.mount('#product-tab-group-app');
    }

    setupModels(): void {
    }

    setupServices(): void {
    }
}
